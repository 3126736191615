import { AppEnv, EnvProps } from "./env-types";
import { npdEnvDefaults } from "./env-defaults";
import { merge } from "lodash";

const env: EnvProps = merge(npdEnvDefaults, {
  ...npdEnvDefaults,
  appEnv: AppEnv.STG,
  baseURL: "https://lineupcard-stg.best-gcp-npd.mlbinfra.com",
});

window.MLBBest = { env };
